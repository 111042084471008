import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

const Notification = ({ open, message, action, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={onClose}
        severity="info"
        action={
          action && (
            <Button color="secondary" size="medium" onClick={action.onClick}>
              {action.label}
            </Button>
          )
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
