import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import * as yup from "yup";
import {
  clearMessage,
  resetAuthState,
  signIn,
} from "../../../state/slices/authSlice";
import "../AuthPages.css";

// Define the validation schema
const schema = yup.object().shape({
  username: yup
    .string()
    .required("L'username ou le nom d'utilisateur est requis"),
  password: yup
    .string()
    .required("Le mot de passe est requis")
    .min(6, "Le mot de passe doit contenir au moins 6 caractères")
    .max(40, "Le mot de passe doit contenir au plus 40 caractères"),
  rememberMe: yup.boolean(),
});



const LoginPage = () => {
  const isAuthenticated = useSelector((state) => !!state.auth.user);
  const router = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authStatus = useSelector((state) => state.auth.status);
  const authError = useSelector((state) => state.auth.error);
  const authMessage = useSelector((state) => state.auth.message);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(resetAuthState());
    return () => {
      dispatch(clearMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    if (authStatus === "succeeded" && authMessage === "Login successful!") {
      navigate("/profile");
    }
  }, [authStatus, authMessage, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      router("/");
    }
  }, [isAuthenticated, router]);

  const onSubmit = async (data) => {
    try {
      dispatch(signIn(data));
    } catch (error) {
      console.error("Login error", error);
    }
  };

  return (
    <div className="auth-card">
      <h2>Bienvenue sur Colocky! 👋</h2>
      <p>Connectez-vous pour accéder à votre compte</p>
      {authStatus === "failed" && (
        <div className="alert error">{authError}</div>
      )}
      {authStatus === "succeeded" && (
        <div className="alert success">{authMessage}</div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-group">
          <label htmlFor="username">nom d'utilisateur ou email</label>
          <input
            type="text"
            id="username"
            {...register("username")}
            placeholder="Entrez votre nom d'utilisateur ou email"
            className={errors.username ? "error" : ""}
          />
          {errors.username && (
            <div className="error-message">{errors.username.message}</div>
          )}
        </div>
        <div className="input-group">
          <label htmlFor="password">Mot de passe</label>
          <input
            type="password"
            id="password"
            {...register("password")}
            placeholder="············"
            className={errors.password ? "error" : ""}
          />
          {errors.password && (
            <div className="error-message">{errors.password.message}</div>
          )}
        </div>
        <Link to="/auth/mot-de-passe-oublier" className="forgot-password">
          Mot de passe oublié?
        </Link>
        <button
          type="submit"
          className="auth-submit-btn"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Connexion en cours..." : "Se connecter"}
        </button>
      </form>
      <p className="auth-alternate-action">
        Nouveau sur notre plateforme?{" "}
        <Link to="/auth/register">Créer un compte</Link>
      </p>
    </div>
  );
};

export default LoginPage;
