import { useParams } from "react-router-dom";
import { verifyEmail } from "../../../services/apis";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setEmailVerified } from "../../../state/slices/authSlice";
import useLocationUtil from "../../../utils/useLocationUtil";

const EmailVerification = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const isAuthenticated = useSelector((state) => !!state.auth.user);

  const { navigateToStoredRoute } = useLocationUtil();

  useEffect(() => {
    const triggerVerify = async () => {
      try {
        const response = await verifyEmail(token);
        alert(response.data);

        // Mettre à jour l'état global et localStorage
        dispatch(setEmailVerified(true));
        navigateToStoredRoute();
      } catch (error) {
        alert(
          error.response
            ? error.response.data
            : "An error occurred during verification."
        );
      }
    };

    if (isAuthenticated) {
      triggerVerify();
    }
  }, [dispatch, isAuthenticated, navigateToStoredRoute, token]);

  const handleVerify = async (e) => {
    e.preventDefault();

    try {
      const response = await verifyEmail(token);
      alert(response.data);

      dispatch(setEmailVerified(true));
      navigateToStoredRoute();
    } catch (error) {
      alert(
        error.response
          ? error.response.data
          : "An error occurred during verification."
      );
    }
  };

  return (
    <div className="auth-card">
      <h2>{"Vérifier votre email"}</h2>
      <button type="submit" className="auth-submit-btn" onClick={handleVerify}>
        {"Vérifier"}
      </button>
    </div>
  );
};

export default EmailVerification;
