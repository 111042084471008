import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useLocationUtil = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const storeCurrentRoute = useCallback(() => {
    const currentRoute = location.pathname;
    localStorage.setItem("currentRoute", currentRoute);
    return currentRoute;
  }, [location.pathname]);

  const navigateToStoredRoute = useCallback((removeAfterFinish = true) => {
    const storedRoute = localStorage.getItem("currentRoute");
    if (storedRoute) {
      navigate(storedRoute);
      if (removeAfterFinish) {
        localStorage.removeItem("currentRoute");
      }
    } else {
      navigate("/");
    }
  }, [navigate]);

  return { storeCurrentRoute, navigateToStoredRoute };
};

export default useLocationUtil;
