import React from "react";
import { Link, Outlet } from "react-router-dom";
import { IMAGES } from "../../constants/images";

const AuthLayout = () => {


  return (
    <div className="auth-page">
      <Link to={"/"} className="logo">
        <img src={IMAGES.logo} alt="Colocky" />
      </Link>
      <Outlet />
      <div >
        <p>&copy; {new Date().getFullYear()} Colocky. All rights reserved.</p>
      </div>
    </div>
  );
};

export default AuthLayout;
