import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  clearMessage,
  resetAuthState,
  signUp,
} from "../../../state/slices/authSlice";
import "../AuthPages.css";

const schema = yup.object().shape({
  username: yup
    .string()
    .required("Le nom d'utilisateur est requis")
    .min(3, "Le nom d'utilisateur doit contenir au moins 3 caractères"),
  email: yup
    .string()
    .required("L'email est requis")
    .email("L'email n'est pas valide"),
  password: yup
    .string()
    .required("Le mot de passe est requis")
    .min(6, "Le mot de passe doit contenir au moins 6 caractères")
    .max(40, "Le mot de passe doit contenir au plus 40 caractères"),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Les mots de passe doivent correspondre"
    )
    .required("La confirmation du mot de passe est requise"),
  acceptTerms: yup
    .boolean()
    .oneOf([true], "Vous devez accepter les conditions d'utilisation"),
});

const RegisterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authStatus = useSelector((state) => state.auth.status);
  const authError = useSelector((state) => state.auth.error);
  const authMessage = useSelector((state) => state.auth.message);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const isAuthenticated = useSelector((state) => !!state.auth.user);
  const router = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      router("/");
    }
  }, [isAuthenticated, router]);
  
  useEffect(() => {
    dispatch(resetAuthState());
    return () => {
      dispatch(clearMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      authStatus === "succeeded" &&
      authMessage === "Registration successful! Please sign in."
    ) {
      navigate("/auth/login");
    }
  }, [authStatus, authMessage, navigate]);

  const onSubmit = async (data) => {
    try {
      dispatch(signUp(data));
    } catch (error) {
      console.error("Login error", error);
    }
  };

  const handleReadPolicies = () => {
    const formData = getValues();
    console.log("Data sent to policies:", formData);
    navigate("/policies", { state: { formData } });
  };

  useEffect(() => {
    const { state } = location;
    if (state && state.formData) {
      console.log("Data received from policies:", state.formData);
      Object.keys(state.formData).forEach((key) => {
        setValue(key, state.formData[key]);
      });
    }
  }, [location, setValue]);


  return (
    <div className="auth-card">
      <h2>Créer un compte Colocky </h2>
      <p>Rejoignez notre communauté de colocataires</p>
      {authStatus === "failed" && (
        <div className="alert error">{authError}</div>
      )}
      {authStatus === "succeeded" && (
        <div className="alert success">{authMessage}</div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-group">
          <label htmlFor="username">Nom d'utilisateur</label>
          <input
            type="text"
            id="username"
            {...register("username")}
            placeholder="Choisissez un nom d'utilisateur"
            className={errors.username ? "error" : ""}
          />
          {errors.username && (
            <div className="error-message">{errors.username.message}</div>
          )}
        </div>
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            {...register("email")}
            placeholder="Entrez votre email"
            className={errors.email ? "error" : ""}
          />
          {errors.email && (
            <div className="error-message">{errors.email.message}</div>
          )}
        </div>
        <div className="input-group">
          <label htmlFor="password">Mot de passe</label>
          <input
            type="password"
            id="password"
            {...register("password")}
            placeholder="Choisissez un mot de passe"
            className={errors.password ? "error" : ""}
          />
          {errors.password && (
            <div className="error-message">{errors.password.message}</div>
          )}
        </div>
        <div className="input-group">
          <label htmlFor="confirm-password">Confirmer le mot de passe</label>
          <input
            type="password"
            id="confirm-password"
            {...register("confirmPassword")}
            placeholder="Confirmer le mot de passe"
            className={errors.confirmPassword ? "error" : ""}
          />
          {errors.confirmPassword && (
            <div className="error-message">
              {errors.confirmPassword.message}
            </div>
          )}
        </div>
        <div className="remember-me">
          <input
            type="checkbox"
            id="accept-terms"
            {...register("acceptTerms")}
          />
          <label htmlFor="accept-terms">
            J'accepte{" "}
            <span
              onClick={handleReadPolicies}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              les conditions d'utilisation
            </span>
          </label>
        </div>
        {errors.acceptTerms && (
          <div className="error-message">{errors.acceptTerms.message}</div>
        )}
        <button
          type="submit"
          className="auth-submit-btn"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Inscription en cours..." : "S'inscrire"}
        </button>
      </form>
      <p className="auth-alternate-action">
        Déjà un compte? <Link to="/auth/login">Se connecter</Link>
      </p>
    </div>
  );
};

export default RegisterPage;
