import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ColocHomeList from '../components/coloc/offreColoc/ColocHomeList';
import DemandeHomeList from '../components/coloc/demandeColoc/DemandeHomeList';
import Herosection from '../components/Herosection';
import HowItWork from '../components/HowItWorks';
import Reviews from '../components/reviews/Reviews';
import Subscription from '../components/Subscription';
import ListVilles from '../components/Ville/ListVilles';
import { getAllDemandes, getAllOffres, resendVerificationToken } from '../services/apis';
import BlogSection from '../components/blog/BlogSection';
import Notification from '../components/notification/Notification';

const HomePage = () => {
  const [notification, setNotification] = useState({ open: false, message: '', action: null });
  const auth = useSelector((state) => state.auth);

  const handleResendVerification = useCallback(() => {
    if (auth.user && auth.user.email) {
      resendVerificationToken(auth.user.email)
        .then(() => {
          setNotification({
            open: true,
            message: 'E-mail de vérification envoyé avec succès !',
          });
        })
        .catch((error) =>
          console.error("Erreur lors de l'envoi de l'e-mail de vérification :", error)
        );
    }
  }, [auth.user]);

  useEffect(() => {
    if (auth.user && !auth.emailVerified) {
      setNotification({
        open: true,
        message: 'Veuillez vérifier votre adresse e-mail pour profiter d’une expérience optimale.',
        action: {
          label: 'Renvoyer la vérification',
          onClick: handleResendVerification,
        },
      });
    }
  }, [auth.emailVerified, handleResendVerification]);

  return (
    <div className="home-page" style={{ fontFamily: 'Poppins, sans-serif' }}>
      <Herosection />
      <ListVilles />
      <HowItWork />
      <ColocHomeList
        LinktoMore="/coloc/offres"
        getLogementsApi={() => getAllOffres(0, 6)}
        title="Offres"
      />
      <DemandeHomeList
        LinktoMore="/coloc/demandes"
        getLogementsApi={() => getAllDemandes(0, 6)}
        title="Demandes"
      />
      <BlogSection />
      <Reviews />
      <Subscription />
      <Notification
        open={notification.open}
        message={notification.message}
        action={notification.action}
        onClose={() => setNotification({ ...notification, open: false })}
      />
    </div>
  );
};

export default HomePage;
