import React, { useState } from "react";
import { User, Edit, Calendar, Briefcase } from "lucide-react";
import { useSelector } from "react-redux";
import "./ProfileNav.css";
import { resendVerificationToken } from "../../../services/apis";

const ProfileNav = ({ profileData, handleNavigation, identityVerified }) => {
  const demandeFilled = useSelector((state) => state.auth.demandeFilled);
  const [isModalOpen, setIsModalOpen] = useState(false);            // Modal visibility state
  const [emailSent, setEmailSent] = useState(null);                 // Email sent status (null, success, or failure)


  const handleVerificationClick = async () => {
    try{
      await resendVerificationToken(profileData?.userInfo?.email)
      setEmailSent('success');
      setIsModalOpen(true);  // Open modal
    }catch(error){
      console.log(error);
      setIsModalOpen('failure');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };

  // Check if profileData.offres is an array and has at least one item
  const hasOffers = Array.isArray(profileData.offres) && profileData.offres.length > 0;

  const navItems = [
    { path: "/profile", icon: User, text: "Profil", active: true },
    { path: "/edit-profile", icon: Edit, text: "Modifier votre profil" },
    demandeFilled && {
      path: "/edit-demande/" + (profileData.userInfo?.demande?.id || ''),
      icon: Calendar,
      text: "Modifier votre demande",
    },
    hasOffers && {
      path: "/offres",
      icon: Briefcase,
      text: "Gérer vos offres",
      badge: profileData.offres.length,
    },
  ].filter(Boolean);


  const modalStyles = {
    display: isModalOpen ? 'block' : 'none',  // Show/hide based on state
    position: 'fixed',
    zIndex: '1000',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '500px',
    textAlign: 'center',
  };

  const overlayStyles = {
    display: isModalOpen ? 'block' : 'none',  // Show/hide overlay
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '999',
  };


  return (
    <aside className="profile-nav">
      <div className="user-info">
        <div className="user-avatar">
          {profileData.userInfo?.nom
            ? profileData.userInfo.nom.charAt(0).toUpperCase()
            : "N"}
        </div>
        <h1 className="user-name">
          {profileData.userInfo?.nom || "N/A"}{" "}
          {profileData.userInfo?.prenom || "N/A"}
        </h1>
        <p className="user-email">{profileData.userInfo?.email}</p>
        <div class="d-flex justify-content-center align-items-center">
          <span className={`btn ${identityVerified ? 'btn-success' : 'btn-primary'} p-6`}>
            {identityVerified ? "Identité vérifié" :
              <button onClick={handleVerificationClick}>
                Vérifier identité
              </button>
            }
          </span>

          {isModalOpen && (
            <>
              <div style={overlayStyles} onClick={closeModal}></div>
              {/* Modal */}
              <div style={modalStyles}>
                <h2>{emailSent === 'success' ? 'Email de vérification envoyé avec succès!' : 'Échec de l\'envoi de l\'email.'}</h2>
                <button className="btn btn-primary" onClick={closeModal}>Fermer</button>
              </div>
            </>
          )}


        </div>

      </div>
      <nav className="nav-menu">
        {navItems.map((item, index) => (
          <button
            key={index}
            onClick={() => handleNavigation(item.path)}
            className={`nav-item ${item.active ? "active" : ""}`}
          >
            <item.icon className="nav-icon" />
            <span>{item.text}</span>
            {item.badge !== undefined && (
              <span className="badge">{item.badge}</span>
            )}
          </button>
        ))}
      </nav>
    </aside>
  );
};

export default ProfileNav;
